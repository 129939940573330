import React from 'react';

export default ({ color }) => (
   <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
    <title>icon-authorize</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g id="Authorize-Dropdown" transform="translate(-701.000000, -219.000000)" stroke={color}>
            <g id="icon-authorize" transform="translate(702.000000, 220.000000)">
                <g id="icon-transactions">
                    <path d="M9,27 L36,27" id="Path" strokeWidth="2"></path>
                    <polyline id="Path" strokeWidth="2" points="9 36 0 27 9 18"></polyline>
                    <path d="M27,9 L0,9" id="Path" strokeWidth="2"></path>
                    <polyline id="Path" strokeWidth="2" points="27 0 36 9 27 18"></polyline>
                </g>
            </g>
        </g>
    </g>
</svg>
);
